import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import projectPreview from '../components/projectPreview'
import Image from '../components/image'

const First = styled.h1`
    max-width: 900px;
    margin: 2em 0 4em 0;
    font-weight: 100;
    span {
        opacity: 0.2;
    }
`

const Second = styled.h1`
    max-width: 900px;
    margin: 0em 0 1em 0;
    font-weight: 100;
    * > a {
        color: inherit !important;
    }
`

const Third = styled.h1`
    max-width: 900px;
    margin: 4em 0 0em 0;
    font-weight: 100;
`

const List = styled.ul`
    font-size: 2em;
    line-height: 125%;
    max-width: 900px;
    margin: 0em 0 4em 0;
    font-weight: 100;
    padding: 0;
    a {
       text-decoration: none;
       color: inherit;
    }
    li {
        list-style: none;
        line-height: 200%;
    }
`

const ProjectGrid = styled.div`
    margin-bottom: 2em;
`

function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }

const IndexPage = ({ data }) => {
    const { edges } = data.allMarkdownRemark
    const { t } = useTranslation('homepage')
    const { i18n } = useTranslation('projects')
    const AllPosts = edges
        // eslint-disable-next-line react/prop-types
        .filter(edge => !!edge.node.frontmatter.date && edge.node.frontmatter.key === i18n.language) // You can filter your posts based on some criteria
        .map(edge => projectPreview(edge))
    const MangaPosts = edges
        // eslint-disable-next-line react/prop-types
        .filter(edge => !!edge.node.frontmatter.date && edge.node.frontmatter.key === i18n.language && edge.node.frontmatter.category === 'manga') // You can filter your posts based on some criteria
        .map(edge => projectPreview(edge))
    const AnimePosts = edges
        // eslint-disable-next-line react/prop-types
        .filter(edge => !!edge.node.frontmatter.date && edge.node.frontmatter.key === i18n.language && edge.node.frontmatter.category === 'anime') // You can filter your posts based on some criteria
        .map(edge => projectPreview(edge))
    const OtherPosts = edges
        // eslint-disable-next-line react/prop-types
        .filter(edge => !!edge.node.frontmatter.date && edge.node.frontmatter.key === i18n.language && edge.node.frontmatter.category === 'others') // You can filter your posts based on some criteria
        .map(edge => projectPreview(edge))
    return (
        <Layout>
            <SEO title={t('title')} />

            <First>
                {t('first').split('\n').map(i => {
                    return <Markdown key= {i} source={i} escapeHtml={false} />
                })}
            </First>

            {/* <ProjectGrid className="projectGrid">{AllPosts}</ProjectGrid> */}
            <h1 style={{fontSize: '4em', margin: '3em 0 1em 0', opacity: '0.1'}}>{t('manga')}</h1>
            <ProjectGrid className="projectGrid">{MangaPosts}</ProjectGrid>
            <h1 style={{fontSize: '4em', margin: '3em 0 1em 0', opacity: '0.1'}}>{t('anime')}</h1>
            <ProjectGrid className="projectGrid">{AnimePosts}</ProjectGrid>
            <h1 style={{fontSize: '4em', margin: '3em 0 1em 0', opacity: '0.1'}}>{t('others')}</h1>
            <ProjectGrid className="projectGrid">{OtherPosts}</ProjectGrid>


            <h1 style={{fontSize: '4em', margin: '3em 0 1em 0', opacity: '0.9'}}>{t('about')}</h1>
            <Second>
                {t('second').split('\n').map(i => {
                    return <Markdown key= {i} source={i} escapeHtml={false} renderers={{link: LinkRenderer}}/>
                })}
            </Second>

            {/* <hr style={{border: '2px solid rgba(0,0,0,0.1)'}}/> */}

            <List>
                {/* <li><a href="mailto:adriasaborido@gmail.com">adriasaborido@gmail.com</a></li>
                <li><a href="https://linkedin.com/in/adriasaboridovert">linkedin.com/in/adriasaboridovert</a></li> */}
                <Image/>
                {/*<li><a href="mailto:adriasaborido@gmail.com">AdriaSaborido@gmail.com</a></li> */}
                {/* <li><a href="http://calend.ly/">→ Calend.ly</a></li> */}
            </List>


        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        isbn
                        subtitle
                        description
                        category
                        key
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1400) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
IndexPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

IndexPage.defaultProps = {
    data: {},
}